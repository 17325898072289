<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <!--인허가 요청정보-->
          <c-card class="cardClassDetailForm" title="LBL0010285">
            <template slot="card-button">
              <q-btn-group outline >
                <!--삭제-->
                <c-btn
                  v-show="editable && param.limLicenseRequestId && isDelete && isRequestUser"
                  label="LBLREMOVE"
                  icon="delete_forever"
                  @btnClicked="deleteData"/>
                <!--저장-->
                <c-btn
                  v-show="editable && isStepReq1 && isRequestUser"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <!--요청완료-->
                <c-btn
                  v-show="editable && param.limLicenseRequestId && isStepReq && isRequestUser"
                  label="LBL0010286"
                  icon="save"
                  @btnClicked="saveEnd"/>
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-9">
                <!--인허가 요청명-->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010276"
                  name="licenseRequestName"
                  v-model="data.licenseRequestName">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-3">
                <!--요청부서 & 요청자-->
                <c-field
                  :editable="editable"
                  :disabled="true"
                  :data="data"
                  deptValue="requestDeptCd"
                  type="dept_user"
                  label="LBL0010287"
                  name="requestUserId"
                  v-model="data.requestUserId">
                </c-field>
              </div>
              <div class="col-3">
                <!--검토부서 & 검토자-->
                <c-field
                  :required="true"
                  :editable="editable"
                  :data="data"
                  deptValue="reviewDeptCd"
                  type="dept_user"
                  label="LBL0010288"
                  name="reviewUserId"
                  v-model="data.reviewUserId">
                </c-field>
              </div>
              <div class="col-3">
                <!--인허가 완료 요청일-->
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  label="LBL0010289"
                  name="completeRequestDate"
                  v-model="data.completeRequestDate">
                </c-datepicker>
              </div>
                <!--MOC번호-->
              <!-- <div class="col-3">
                <c-text
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'searchMoc', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeMoc', color: 'red' }
                  ] : null"
                  :editable="editable"
                  :disabled="true"
                  label="LBLMOCNO"
                  name="sopMocId"
                  v-model="data.sopMocId"
                  @searchMoc="searchMoc"
                  @removeMoc="removeMoc" />
              </div> -->
              <div class="col-12">
                <!--요청 내용-->
                <c-textarea
                  label="LBL0010290"
                  :editable="editable"
                  name="requestContent"
                  :rows="2"
                  v-model="data.requestContent" />
              </div>
              <div class="col-3">
                <c-select
                  :disabled="true"
                  codeGroupCd="LICENSE_REQUEST_STEP_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="requestStepCd"
                  label="LBLSTEPPROGRESS"
                  v-model="data.requestStepCd"
                ></c-select>
              </div>
              <div class="col-9">
                <!--기각사유-->
                <c-textarea
                  v-show="data.requestStepCd == 'RSC0999999'"
                  label="LBL0010291"
                  :editable="false"
                  name="dismissReason"
                  :rows="1"
                  v-model="data.dismissReason" />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" v-if="param.limLicenseRequestId">
        <!--인허가 검토 목록-->
        <c-table
          ref="table"
          title="LBL0010292"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          gridHeight="300px"
        ></c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" v-if="param.limLicenseRequestId">
        <q-form ref="editForm2">
          <!--인허가 검토 상세-->
          <c-card title="LBL0010293" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!--등록-->
                <c-btn v-if="editable && data.requestStepCd=='RSC0000001'" label="LBLREG" icon="add" @btnClicked="addReview" />
                <!--삭제-->
                <c-btn
                  v-if="editable && deleteable && data.requestStepCd=='RSC0000001'"
                  label="LBLREMOVE"
                  icon="delete_forever"
                  @btnClicked="deleteReview"/>
                <!--저장-->
                <c-btn
                  v-if="editable && saveable && data.requestStepCd=='RSC0000001'"
                  :url="saveReviewUrl"
                  :isSubmit="isReviewSave"
                  :param="dataReview"
                  :mappingType="saveReviewType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveReview"
                  @btnCallback="saveReviewCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <!--관련법규-->
                <c-text
                  :required="true"
                  :editable="false"
                  label="LBL0010245"
                  name="relatedLawsName"
                  v-model="dataReview.relatedLawsName">
                </c-text>
              </div>
              <div class="col-6">
                <!--인허가종류-->
                <c-text
                  :required="true"
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'searchLicenseKindId', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeLicenseKindId', color: 'red' }
                  ] : null"
                  :editable="editable && dataeditable"
                  :readonly="true"
                  label="LBL0010249"
                  name="limLicenseKindName"
                  v-model="dataReview.limLicenseKindName"
                  @searchLicenseKindId="searchLicenseKindId"
                  @removeLicenseKindId="removeLicenseKindId">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 검토담당부서 & 검토담당자 -->
                <c-field
                  :required="true"
                  :editable="editable && dataeditable"
                  :data="dataReview"
                  deptValue="reviewDeptCd"
                  type="dept_user"
                  label="LBL0010294"
                  name="reviewUserId"
                  v-model="dataReview.reviewUserId">
                </c-field>
              </div>
              <div class="col-6">
                <!--검토상태-->
                <c-select
                  :disabled="true"
                  :editable="editable && dataeditable"
                  codeGroupCd="LIM_LICENSE_KIND_STAUS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="limLicenseKindStatusCd"
                  label="LBL0010278"
                  v-model="dataReview.limLicenseKindStatusCd"
                ></c-select>
              </div>
              <div class="col-6">
                <!--갱신 여부-->
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBL0010295"
                  name="renewalFlag"
                  v-model="dataReview.renewalFlag"
                />
              </div>
              <div class="col-6">
                <!--갱신대상 인허가-->
                <c-text
                  v-if="dataReview.renewalFlag == 'Y'"
                  :required="dataReview.renewalFlag == 'Y'"
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'searchLicenseId', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeLicenseId', color: 'red' }
                  ] : null"
                  :editable="editable && dataeditable"
                  :readonly="true"
                  label="LBL0010296"
                  name="asLicenseSeqName"
                  v-model="dataReview.asLicenseSeqName"
                  @searchLicenseId="searchLicenseId"
                  @removeLicenseId="removeLicenseId">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "license-request-detail",
  props: {
    param: {
      type: Object,
      default: () => ({
        limLicenseRequestId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveUrl: transactionConfig.sop.lim.lincense.request.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      isSave: false,
      isSaveEnd: false,
      isStepReq: false,
      isStepReq1: false,
      isDelete: false,
      isRequestUser: false,
      isReviewSave: false,
      detailReviewUrl: '',
      reviewlistUrl: '',
      reviewinsertUrl: '',
      reviewupdateUrl: '',
      reviewdeleteUrl: '',
      saveReviewUrl: transactionConfig.sop.lim.lincense.review.insert.url,
      saveReviewType: 'POST',
      isReviewUser: false,
      isReqReviewUser: false,
      data: {
        limLicenseRequestId: '',  // 인허가 요청 일련번호
        plantCd: '',  // 사업장 코드
        licenseRequestName: '',  // 인허가 요청명
        requestDeptCd: '',  // 요청부서 코드
        requestUserId: '',  // 요청자
        completeRequestDate: '',  // 인허가 완료 요청일
        requestContent: '',  // 요청 내용
        sopMocId: '',  // MOC 일련번호
        requestStepCd: 'RSC0000001',  // 요청및검토 진행 단계 : RSC0000002(요청),RSC0000003(검토 및 진행중),RSC0999999(기각),RSC1000000(완료)
        dismissReason: '',  // 기각사유
        reviewOpinion: '',  // 검토 종합 의견
        reviewUserId: '',  // 종합 검토자
        reviewDeptCd: '',  // 검토 부서
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      dataReview: {
        limLicenseReviewId: '',  // 인허가 검토 일련번호
        limLicenseRequestId: '',  // 인허가 요청 일련번호
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',
        relatedLawsName: '',
        renewalFlag: 'N',  // 갱신 여부, 갱신인 경우는 기존인허가번호 입력
        reviewDeptCd: '',  // 검토부서
        reviewUserId: '',  // 검토자
        reviewOpinion: '',  // 인허가 검토 의견
        reviewCompleteFlag: 'N',  // 검토완료 여부
        limLicenseKindStatusCd: null,  // 인허가종류별 검토상태
        asLicenseSeq: '',  // 기존 인허가 일련번호
        asLicenseSeqName: '',  // 기존 인허가명
        newLicenseSeq: '',  // 등록 인허가 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      grid: {
        columns: [
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            //관련법규
            label: 'LBL0010245',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            // 인허가종류
            label: 'LBL0010249',
            align: 'center',
            sortable: true,
          },
          {
            name: 'reviewDeptName',
            field: 'reviewDeptName',
            // 검토부서
            label: 'LBL0010273',
            align: 'center',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            // 검토자
            label: 'LBLREVIEWER',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limLicenseKindStatusName',
            field: 'limLicenseKindStatusName',
            // 검토상태
            label: 'LBL0010278',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.lim.lincense.request.get.url;
      this.insertUrl = transactionConfig.sop.lim.lincense.request.insert.url;
      this.updateUrl = transactionConfig.sop.lim.lincense.request.update.url;
      this.deleteUrl = transactionConfig.sop.lim.lincense.request.delete.url;

      this.reviewlistUrl = selectConfig.sop.lim.lincense.review.list.url;
      this.detailReviewUrl = selectConfig.sop.lim.lincense.review.get.url;
      this.reviewinsertUrl = transactionConfig.sop.lim.lincense.review.insert.url;
      this.reviewupdateUrl = transactionConfig.sop.lim.lincense.review.update.url;
      this.reviewdeleteUrl = transactionConfig.sop.lim.lincense.review.delete.url;
      this.dataeditable = false;
      this.getData();
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getReviewList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.reviewlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {limLicenseRequestId: this.param.limLicenseRequestId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailReviewUrl, row.limLicenseReviewId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.dataReview = _result.data;
        this.saveReviewUrl = this.reviewupdateUrl;
        this.saveReviewType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addReview() {
      this.saveable = true;
      this.deleteable = false;
      this.saveReviewUrl = this.reviewinsertUrl;
      this.saveReviewType = 'POST';
      this.updateMode = false;
      this.dataReview = {
        limLicenseReviewId: '',  // 인허가 검토 일련번호
        limLicenseRequestId: this.param.limLicenseRequestId,  // 인허가 요청 일련번호
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',
        relatedLawsName: '',
        renewalFlag: 'N',  // 갱신 여부, 갱신인 경우는 기존인허가번호 입력
        reviewDeptCd: '',  // 검토부서
        reviewUserId: '',  // 검토자
        reviewOpinion: '',  // 인허가 검토 의견
        reviewCompleteFlag: 'N',  // 검토완료 여부
        limLicenseKindStatusCd: 'LLKSC00001',  // 인허가종류별 검토상태
        asLicenseSeq: '',  // 기존 인허가 일련번호
        asLicenseSeqName: '',  // 기존 인허가명
        newLicenseSeq: '',  // 등록 인허가 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      };
      this.dataeditable = true;
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.dataReview = {
        limLicenseReviewId: '',  // 인허가 검토 일련번호
        limLicenseRequestId: this.param.limLicenseRequestId,  // 인허가 요청 일련번호
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',
        relatedLawsName: '',
        renewalFlag: 'N',  // 갱신 여부, 갱신인 경우는 기존인허가번호 입력
        reviewDeptCd: '',  // 검토부서
        reviewUserId: '',  // 검토자
        reviewOpinion: '',  // 인허가 검토 의견
        reviewCompleteFlag: 'N',  // 검토완료 여부
        limLicenseKindStatusCd: null,  // 인허가종류별 검토상태
        asLicenseSeq: '',  // 기존 인허가 일련번호
        asLicenseSeqName: '',  // 기존 인허가명
        newLicenseSeq: '',  // 등록 인허가 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      };
    },
    saveReview() {
      this.$refs['editForm2'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.dataReview.regUserId = this.$store.getters.user.userId;
              this.dataReview.chgUserId = this.$store.getters.user.userId;
              if (this.dataReview.renewalFlag == 'N') {
                this.dataReview.asLicenseSeq = '';
                this.dataReview.asLicenseSeqName = '';
              }
              this.isReviewSave = !this.isReviewSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteReview() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.dataReview.limLicenseReviewId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveReviewCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ limLicenseRequestId: result.data.limLicenseRequestId })
      } else {
        this.getReviewList();
      }
    },
    getData() {
      if (this.param.limLicenseRequestId) {
        this.$http.url = this.$format(this.detailUrl, this.param.limLicenseRequestId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (this.data.requestStepCd == 'RSC0000001') { // 작성중
            this.isStepReq = true;
            this.isStepReq1 = true;
          } else {
            this.isStepReq1 = false;
          }
          if (this.data.requestStepCd == 'RSC0000002') { // 요청완료
            this.isStepReq = false;
          }
          if (this.data.requestStepCd == 'RSC0000003' || this.data.requestStepCd == 'RSC1000000') {
            this.isDelete = false;
          } else {
            this.isDelete = true;
          }
          // 요청자나 요청부서원일 경우 버튼실행 가능
          if (this.data.requestUserId == this.$store.getters.user.userId || this.data.requestDeptCd == this.$store.getters.user.deptCd) {
            this.isRequestUser = true;
          }
        },);
      } else {
        this.isRequestUser = true;
        this.isStepReq = false;
        this.isStepReq1 = true;
        this.data.requestDeptCd = this.$store.getters.user.deptCd;  // 요청부서 코드
        this.data.requestUserId = this.$store.getters.user.userId;  // 요청자
      }
    },
    saveData() {
      if (this.param.limLicenseRequestId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveEnd() {
      if (this.grid.data.length == 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '인허가 검토목록이 없습니다. 검토를 1건 이상 등록 후 요청완료가 가능합니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',  // 확인
              message: 'MSG0010016',  // 인허가요청을 하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'warning', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.requestStepCd = 'RSC0000002';
                this.data.chgUserId = this.$store.getters.user.userId;

                this.$http.url = this.updateUrl;
                this.$http.type = 'PUT';
                this.$http.param = this.data
                this.$http.request(() => {
                  this.getData();
                  window.getApp.$emit('APP_REQUEST_SUCCESS');
                },);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSG0010017',   // 인허가요청을 삭제하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.limLicenseRequestId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.param.limLicenseRequestId = result.data.limLicenseRequestId
      }
      this.getData();
    },
    searchMoc() {
      this.popupOptions.title = 'LBL0010268'; // MOC 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data) {
        this.data.sopMocId = data.chngNum
      }
    },
    removeMoc() {
      this.data.sopMocId = null
    },
    searchLicenseKindId() {
      this.popupOptions.title = 'LBL0010269'; // 관련법규/인허가종류 검색
      this.popupOptions.param = {
        type: 'single',
        chkPlantCd: this.data.plantCd, // 사업장번호를 넘기면 갱신대상인지 신규대상인지 확인
      };
      this.popupOptions.target = () => import(`${'@/pages/common/license/licenseKindPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLicenseKindId;
    },
    closeLicenseKindId(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.dataReview.relatedLawsName = data[0].relatedLawsName;
        this.dataReview.limLicenseKindId = data[0].limLicenseKindId;
        this.dataReview.limLicenseKindName = data[0].limLicenseKindName;
        this.dataReview.renewalFlag = data[0].renewalFlag;
        this.dataReview.asLicenseSeq = data[0].asLicenseSeq;
        this.dataReview.asLicenseSeqName = data[0].asLicenseSeqName;
      }
    },
    removeLicenseKindId() {
      this.dataReview.relatedLawsName = '';
      this.dataReview.limLicenseKindId = '';
      this.dataReview.limLicenseKindName = '';
    },
    searchLicenseId() {
      this.popupOptions.title = 'LBL0010297'; // 인허가현황 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/license/licensePop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLicenseId;
    },
    closeLicenseId(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.dataReview.asLicenseSeqName = data[0].limLicenseKindName;
        this.dataReview.asLicenseSeq = data[0].limLicenseKindId;
      }
    },
    removeLicenseId() {
      this.dataReview.asLicenseSeq = '';
      this.dataReview.asLicenseSeqName = '';
    },
  }
};
</script>
